.title-dark {
  font-family: 'Soyuz Grotesk';
  font-size: 44px;
  font-weight: 700;
  letter-spacing: 0.03em;
  line-height: 58px;
  color: var(--color-text);
}

.title-light {
  font-family: 'Soyuz Grotesk';
  font-size: 44px;
  font-weight: 700;
  letter-spacing: 0.03em;
  line-height: 58px;
  color: var(--color-white);
}

.text-important {
  background-color: var(--color-main);
}
