.download {
  padding-top: 30px;
  &__container {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    border-radius: 30px;
    margin: 0 auto 100px;
    padding: 50px 60px;
    background-color: var(--color-main);
  }

  &__title {
    text-align: start;
  }

  &__description {
    font-size: 24px;
    font-weight: 300;
    line-height: 34px;
    text-align: start;
    color: var(--color-white);
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__links {
    display: flex;
    gap: 30px;
    @media (max-width: (540px)) {
      display: flex;
      flex-direction: column;
    }
  }

  &__logo {
    @media (max-width: (576px)) {
      display: none;
    }
  }
}
