.founder {
  &__img {
    height: 550px;
    overflow: hidden;

    border-radius: 40px;
    @media (max-width: (540px)) {
      height: 450px;
    }
    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrapper {
    margin-top: 70px;
  }

  &__name {
    font-size: 36px;
    font-weight: 600;
    line-height: 43px;
  }

  &__position {
    margin-top: 10px;
    // font-size: 14px;
    // font-weight: 300;
    // line-height: 20px;

    font-size: 24px;
    font-weight: 300;
    line-height: 34px;

    color: var(--color-text);
  }

  &__list {
    margin-top: 30px;
    // font-size: 16px;
    // font-weight: 300;
    // line-height: 22px;

    font-size: 24px;
    font-weight: 300;
    line-height: 34px;

    padding-left: 25px;

    &-item {
      list-style-type: disc;
    }
  }
}
