// Raleway
@font-face {
  font-family: 'Raleway';
  src: local('Raleway-Light'), url('../fonts/raleway/Raleway-Light.ttf') format('ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway-Regular'), url('../fonts/raleway/Raleway-Regular.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway-Medium'), url('../fonts/raleway/Raleway-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway-Bold'), url('../fonts/raleway/Raleway-Bold.ttf') format('ttf');
  font-weight: 700;
  font-style: normal;
}

// Space Grotesk
@font-face {
  font-family: 'Space Grotesk';
  src: local('SpaceGrotesk-Medium'),
    url('../fonts/space-grotesk/SpaceGrotesk-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Space Grotesk';
  src: local('SpaceGrotesk-SemiBold'),
    url('../fonts/space-grotesk/SpaceGrotesk-SemiBold.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Space Grotesk';
  src: local('SpaceGrotesk-Bold'), url('../fonts/space-grotesk/SpaceGrotesk-Bold.ttf') format('ttf');
  font-weight: 700;
  font-style: normal;
}

// Soyuz Grotesk
@font-face {
  font-family: 'Soyuz Grotesk';
  src: local('SoyuzGrotesk-Bold'),
    url('../fonts/soyuz-grotesk/SoyuzGrotesk-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
