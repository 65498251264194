.partners {
  padding-top: 100px;
  @media (max-width: (576px)) {
    padding-top: 50px;
  }
  &__img {
    height: 270px;
    width: auto;
    @media (max-width: (576px)) {
      display: none;
    }
  }

  &__imgs {
    display: flex;
    height: 60px;
    gap: 30px;
    img {
      height: 100%;
    }
  }
}
