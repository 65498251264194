.slider {
  position: relative;
  width: 100%;
  margin: auto;

  &__item {
    width: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    &-img {
      height: 180px;
      width: 180px;
      //
      border-radius: 20px;
    }
    &-name {
      margin-top: 20px;
      font-size: 21px;
      font-weight: 700;
      line-height: 24px;
      color: var(--color-text);
    }
    &-position {
      margin-top: 5px;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      color: var(--color-text);
    }
  }
}

.swiper {
  max-width: 1000px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  height: 95px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-main);
  border-radius: 50px;
  transition: all 0.3s ease;
}

.swiper-button-prev {
  left: 0px;
}

.swiper-button-next {
  right: 0px;
}

.swiper-button-disabled {
  opacity: 0.4;
}

.swiper-button-icon {
  color: var(--color-white);
}
