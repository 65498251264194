.logo {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 22px;
  font-weight: 600;
  color: var(--color-white);
}

.logo-light {
  color: var(--color-main);
}
