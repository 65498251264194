.navbar {
  &__icon {
    color: var(--color-white);
    &.scrolled {
      color: var(--color-main);
    }
  }

  &__btn {
    padding: 12px 35px;
    border-radius: 16px;
    background-color: var(--color-white);
    color: var(--color-main);
    box-shadow: 0 8px 25px rgba(157, 141, 241, 0.4);
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3 ease-in-out;
    &:hover {
      background-color: var(--color-main-light);
    }
  }

  &__nav {
    &-link {
      color: var(--color-white);
      font-size: 14px;
      border-radius: 20px;
      &.scrolled {
        color: var(--color-main);
      }

      &:hover {
        background-color: var(--color-main-light);
      }
    }
  }
}

.offcanvas {
  background-color: var(--color-main);
}

.navbar__logo {
  color: var(--color-white);
}
.navbar__logo.scrolled {
  color: var(--color-main);
}

.navbar__brand {
  color: var(--color-main);
}
