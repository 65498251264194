html {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Space Grotesk", "Soyuz Grotesk" sans-serif;
  font-weight: 400;
  background-color: var(--color-body-bg);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
