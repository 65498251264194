.numbers {
  padding-top: 82px;
  padding-bottom: 90px;
  @media (max-width: (576px)) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__item {
    height: 124px;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0 30px 0 30px;
    border-radius: 20px;
    background-color: var(--color-white);

    &-title {
      font-family: 'Space Grotesk';
      font-size: 36px;
      font-weight: 700;
      color: var(--color-main);
      line-height: 40px;
    }

    &-descr {
      font-size: 16px;
      font-weight: 300;
    }
  }
}

.certificate {
  padding-top: 100px;
  @media (max-width: (576px)) {
    padding-top: 50px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  &__img {
    height: 100px;
    width: auto;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    color: var(--color-text);
  }

  &__link {
    height: 80px;
    width: 296px;
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-main);
    border-radius: 24px;
    box-shadow: 0 8px 25px rgba(157, 141, 241, 0.4);
    color: var(--color-white);
    font-size: 28px;
    font-weight: 700;
    line-height: 39px;
    text-transform: uppercase;
    transition: all 0.3s ease;
    @media (max-width: (576px)) {
      margin-top: 50px;
    }
  }
}
