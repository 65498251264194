.content {
  &__item {
    height: 400px;
    width: 100%;
    display: flex;
    padding: 50px;
    background-color: var(--color-white);
    border-radius: 40px;
    box-shadow: 0 10px 30px rgba(157, 141, 241, 0.2);

    &-wrapper {
      display: flex;
      flex-direction: column;
    }

    &-title {
      font-family: Soyuz Grotesk;
      font-size: 72px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 79px;
    }

    &-descr {
      // font-size: 16px;
      // font-weight: 300;
      // line-height: 22px;

      font-size: 24px;
      font-weight: 300;
      line-height: 34px;

      padding-top: 30px;
      text-align: start;
    }

    &-link {
      height: 52px;
      max-width: 236px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: auto;
      background-color: var(--color-main);
      color: var(--color-white);
      border-radius: 16px;
      box-shadow: 0 8px 25px rgba(157, 141, 241, 0.4);
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-transform: uppercase;
    }

    &-img {
      align-self: flex-end;
      @media (max-width: (540px)) {
        display: none;
      }
    }
  }

  &__wrapper {
    height: 200px;
    padding-left: 40px;
    padding-right: 30px;
    padding-top: 85px;
    width: 100%;
    @media (max-width: (576px)) {
      margin-bottom: 120px;
    }
    @media (max-width: (540px)) {
      padding-top: 15px;
      margin-bottom: 120px;
    }
  }

  &__title {
    line-height: 48px;
  }

  &__txt {
    padding-top: 40px;
    // font-size: 16px;
    // font-weight: 300;
    // line-height: 22px;

    font-size: 24px;
    font-weight: 300;
    line-height: 34px;

    color: var(--color-text);
  }
}

.content__col:last-child {
  margin-top: 40px;
  @media (max-width: (576px)) {
    margin-top: 0px;
  }
}
