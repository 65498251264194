.social {
  width: 150px;

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-item {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background-color: var(--color-white);
    }

    &-icon {
      font-size: 20px;
      color: var(--color-main);
      margin-bottom: 2px;
    }
  }
}

.social-light {
  .social__list-item {
    background-color: var(--color-main);
  }
  .social__list-icon {
    color: var(--color-white);
  }
}
