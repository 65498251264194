.promo {
  padding-top: 200px;
  padding-bottom: 50px;
  background: var(--color-gradient-bg);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media (max-width: (576px)) {
    padding-top: 150px;
  }

  &__container {
    position: relative;
  }

  &__img {
    position: absolute;
    right: 100px;
    bottom: -100px;
    height: 460px;
    width: auto;
    @media (max-width: (576px)) {
      display: none;
    }
  }

  &__description {
    // font-size: 18px;
    // font-weight: 300;

    font-size: 24px;
    font-weight: 300;
    line-height: 34px;

    color: var(--color-white);
  }

  &__links {
    display: flex;
    gap: 10px;
  }
}
