.advices {
  padding-top: 40px;

  &__cover {
    position: relative;
    max-height: 740px;
    width: 100%;
    overflow: hidden;
  }

  &__img {
    max-height: 720px;
    width: 100%;
    object-fit: cover;
    @media (max-width: (576px)) {
      max-height: 600px;
      width: auto;
    }
  }

  &__title {
    font-family: Soyuz Grotesk;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 0.03em;
    line-height: 58px;
    text-align: start;
  }

  &__description {
    margin-top: 30px;
    font-size: 24px;
    font-weight: 300;
    line-height: 34px;
    text-align: start;
  }
}
