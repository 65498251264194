.header {
  position: fixed;
  top: 20px;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;

  &__container {
    padding: 10px 40px;
    border-radius: 80px;
    background-color: var(--color-gradient-bg);
    transition: all 0.4s ease-in-out;
    &.scrolled {
      color: var(--color-main);
      background-color: var(--color-white);
      box-shadow: -8px 0 10px rgba(157, 141, 241, 0.2), 8px 8px 10px rgba(157, 141, 241, 0.2);
    }
  }
}
